import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ScrollTop } from './components/ScrollTop';
import { PageDataProvider, useLayout } from './core';
import { ActivityDrawer, DrawerMessenger, RightToolbar } from '../partials';
import {
  themeModeSwitchHelper,
  useThemeMode,
} from '../partials/layout/theme-mode/ThemeModeProvider';
import { MenuComponent } from '../assets/ts/components';
import Footer from 'common/components/footer';
import Navbar from 'features/navbar';

const MasterLayout = ({ isPrivate, children }) => {
  // eslint-disable-next-line no-unused-vars
  const { classes } = useLayout();
  const { mode } = useThemeMode();
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 500);
  }, [location.key]);

  useEffect(() => {
    themeModeSwitchHelper(mode);
  }, [mode]);

  return (
    <PageDataProvider>
      <div>
        <div className='page d-flex flex-row flex-column-fluid'>
          <div
            className='wrapper d-flex flex-column flex-row-fluid'
            id='kt_wrapper'
          >
            {isPrivate && <Navbar />}
            {children}
            <Footer />
          </div>
        </div>

        {/* begin:: Drawers */}
        <ActivityDrawer />
        {isPrivate && <RightToolbar />}
        <DrawerMessenger />
        {/* end:: Drawers */}

        {/* begin:: Modals */}
        {/* end:: Modals */}

        <ScrollTop />
      </div>
    </PageDataProvider>
  );
};

export { MasterLayout };
