import {
  createContext,
  useCallback,
  useContext,
  useState,
  useMemo,
  useEffect,
} from 'react';
import {
  fetchUser,
  loginService,
  logoutService,
  registerService,
  requestPasswordResetService,
  requestAccountValidationLinkService,
  validateAccountService,
  resetPasswordService,
} from 'common/api/authService';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMetaContext } from 'contexts/meta';
import { useApi } from 'common/hooks';
import {
  USER_SIGN_UP_SUCCESS_MSG,
  VALIDATE_ACCOUNT_AFTER_SIGNUP_MESSAGE,
} from './constants';
import { LANDING_PAGE } from 'routes/constants';
import { splitPath } from 'common/utils/routing';
import { useEventsContext } from 'contexts/events';
import { USER_IMAGE_UPLOADED_EVENT } from 'contexts/events/constants';

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const { subscribe } = useEventsContext();
  const [user, setUser] = useState(null);
  const { setSuccessToastMessage, setNeutralToastMessage } = useMetaContext();
  const navigate = useNavigate();
  const location = useLocation();
  const { asyncWrapper, asyncGlobalWrapper } = useApi();

  const isProfileOwner = useMemo(() => {
    const userPageId = splitPath(location?.pathname)[2];
    return user?._id === userPageId;
  }, [location?.pathname, user?._id]);

  const logout = useCallback(async () => {
    await logoutService();
    setUser(null);
    // TODO #83 need to figure out better way to handle non-existing pages
    navigate(LANDING_PAGE);
  }, [navigate]);

  const login = useCallback(
    ({ email, password }) => {
      asyncWrapper(async () => {
        const { user } = await loginService({ email, password }).then(
          res => res?.data
        );

        setUser(user);
      });
    },
    [asyncWrapper]
  );

  const requestLinkAPIServiceHandlers = useCallback(
    service => data => {
      asyncWrapper(async () => {
        const { message } = await service(data).then(res => res?.data);

        setNeutralToastMessage(message);
      });
    },
    [asyncWrapper, setNeutralToastMessage]
  );

  const requestAccountValidationLink = useMemo(
    () => requestLinkAPIServiceHandlers(requestAccountValidationLinkService),
    [requestLinkAPIServiceHandlers]
  );

  const validateAccount = useCallback(
    (token, cb) => {
      asyncWrapper(async () => {
        const message = await validateAccountService(token)
          .then(res => {
            const responseMessage = res?.data?.message;
            return `${responseMessage} You can now login and start using cluster-11. 🙌`;
          })
          .catch(err => {
            const errorMessage = err?.response?.data?.message;
            return `${errorMessage} Please request new validation link from the login modal.`;
          });

        cb(message);
      });
    },
    [asyncWrapper]
  );

  const resetPassword = useCallback(
    (token, password, cb) => {
      asyncWrapper(async () => {
        const { message } = await resetPasswordService(token, password)
          .then(res => res?.data)
          .catch(() => {
            return {
              message: `Invalid request. Please request new password reset link from the login modal.`,
            };
          });

        cb(message);
      });
    },
    [asyncWrapper]
  );

  const requestPasswordResetLink = useMemo(
    () => requestLinkAPIServiceHandlers(requestPasswordResetService),
    [requestLinkAPIServiceHandlers]
  );

  const register = useCallback(
    data => {
      asyncWrapper(async () => {
        await registerService(data);
        setSuccessToastMessage(USER_SIGN_UP_SUCCESS_MSG);
        setNeutralToastMessage(VALIDATE_ACCOUNT_AFTER_SIGNUP_MESSAGE);
        navigate(LANDING_PAGE);
      });
    },
    [asyncWrapper, navigate, setNeutralToastMessage, setSuccessToastMessage]
  );

  const loadUser = useCallback(() => {
    asyncGlobalWrapper(async () => {
      await fetchUser(data => {
        if (typeof data === 'object') setUser(data);
      });
    });
  }, [asyncGlobalWrapper]);

  useEffect(() => {
    subscribe(USER_IMAGE_UPLOADED_EVENT, image => {
      setUser(curr => ({ ...curr, image: { url: image } }));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadUser();
  }, [loadUser]);

  const value = {
    user,
    setUser,
    loadUser,
    isProfileOwner,
    isGuestView: !user,

    logout,
    login,
    requestAccountValidationLink,
    validateAccount,
    requestPasswordResetLink,
    resetPassword,
    register,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => useContext(AuthContext);
