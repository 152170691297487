import { useCallback, useState } from 'react';
import { useAuthContext } from 'contexts/auth';

const useProps = () => {
  const { user, logout } = useAuthContext();

  const [selectedMatch, setSelectedMatch] = useState(null);
  const closeMatchModal = useCallback(() => {
    setSelectedMatch(null);
  }, []);

  return {
    user,
    logout,

    selectedMatch,
    closeMatchModal,
  };
};

export default useProps;
