/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';
import { PAGE_TYPES } from 'common/constants/general';
import MaxWidthContainer from 'common/components/max-width-container';
import { usePrivateNavContext } from 'contexts/private-nav';
import './PageWrapper.scss';

const className = 'c-PageWrapper';

const PageWrapper = ({
  type,
  children,
  classNames,
  fullscreen,
  skipMaxWidth,
}) => {
  const { closeNavbarDropdowns } = usePrivateNavContext();

  const fullscreenClassName = fullscreen ? `${className}--full-screen` : '';
  const noSidePaddingClassName = fullscreen
    ? `${className}--no-side-padding`
    : '';

  return (
    <div className={`${classNames}`} onClick={closeNavbarDropdowns}>
      <div className={`${className} ${fullscreenClassName}`}>
        {skipMaxWidth ? (
          <main className={`${className}__content`}>{children}</main>
        ) : (
          <MaxWidthContainer classNames={`${noSidePaddingClassName}`}>
            <main className={`${className}__content`}>{children}</main>
          </MaxWidthContainer>
        )}
      </div>
    </div>
  );
};

PageWrapper.propTypes = {
  children: PropTypes.any,
  type: PropTypes.oneOf(PAGE_TYPES),
  className: PropTypes.string,
  fullscreen: PropTypes.bool,
  edgeToEdgeDisplay: PropTypes.bool,
  skipMaxWidth: PropTypes.bool,
};

PageWrapper.defaultProps = {
  classNames: '',
};

export default PageWrapper;
