import * as bootstrap from 'bootstrap';
import { useEffect, useRef } from 'react';

const useBootstrapTooltip = () => {
  const tooltipRef = useRef(null);

  useEffect(() => {
    let tooltip;
    if (tooltipRef && tooltipRef.current) {
      tooltip = new bootstrap.Tooltip(tooltipRef.current);
    }

    return () => {
      tooltip?.dispose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tooltipRef.current]);

  return tooltipRef;
};

export default useBootstrapTooltip;
