import { useAuthContext } from 'contexts/auth';
import { useNavigate } from 'react-router-dom';
import {
  CLUBS_PAGE_PATH,
  LANDING_PAGE,
  STREET_PATH,
  USER_PROFILE_BASE_PATH,
} from 'routes/constants';

const useNavigation = () => {
  const { user } = useAuthContext();
  const navigate = useNavigate();

  const navigateToClubPage = id => {
    navigate(`${CLUBS_PAGE_PATH}/${id}`);
  };

  const navigateToUserPage = (id, tab = '') => {
    navigate(`${USER_PROFILE_BASE_PATH}/${id}/${tab}`);
  };

  // TODO #85 (https://github.com/cluster-II/project-managing/issues/85)
  const navigateToMyGames = () => {
    navigateToUserPage(user?._id, 'my-games'); // TODO to constant
  };

  const navigateToStreetPage = () => {
    navigate(STREET_PATH);
  };

  const navigateToLandingPage = () => {
    navigate(LANDING_PAGE);
  };

  return {
    navigate,
    navigateToClubPage,
    navigateToUserPage,
    navigateToStreetPage,
    navigateToLandingPage,
    navigateToMyGames,
  };
};

export default useNavigation;
