import { useCallback, useState, useMemo } from 'react';
import useGeneral from './useGeneral';
import usePositions from './usePositions';
import useSkills from './useSkills';
import {
  GENERAL_SCREEN,
  NEXT_SCREEN_LABEL,
  POSITIONS_SCREEN,
  SAVE_PROFILE_LABEL,
  SKILLS_SCREEN,
  SUCCESS_SUBMIT_MESSAGE,
  SUBMIT_ERROR_MESSAGE,
} from '../constants';
import {
  BUTTON_LIGHT_VARIANT,
  BUTTON_EMPHASIZED_VARIANT,
} from 'common/components/button/constants';
import { useApi } from 'common/hooks';
import { updateUserV2 } from 'common/api/userService';
import { formatPlayerAPIBody } from './mappers';
import { useAuthContext } from 'contexts/auth';
import { USER_PLAYER_CARD_UPDATED_EVENT } from 'contexts/events/constants';
import { useEventsContext } from 'contexts/events';

const useProps = onClose => {
  const { user, setUser } = useAuthContext();
  const { emit } = useEventsContext();
  const { apiWrapper } = useApi();
  const generalProps = useGeneral({
    description: user?.description,
    playerGeneralInfo: user?.playerGeneralInfo,
  });
  const skillsProps = useSkills(user?.skills);
  const positionsProps = usePositions(user?.positions);

  const [currentScreen, setCurrentScreen] = useState(GENERAL_SCREEN);
  const getScreenSelector = useCallback(
    screen => () => {
      setCurrentScreen(screen);
    },
    []
  );

  const flowButtonVariant = useMemo(
    () =>
      currentScreen === POSITIONS_SCREEN
        ? BUTTON_EMPHASIZED_VARIANT
        : BUTTON_LIGHT_VARIANT,
    [currentScreen]
  );

  const getNextScreenButtonProps = useCallback(() => {
    if (currentScreen === GENERAL_SCREEN) {
      return {
        onClick: getScreenSelector(SKILLS_SCREEN),
        label: NEXT_SCREEN_LABEL,
      };
    } else if (currentScreen === SKILLS_SCREEN) {
      return {
        onClick: getScreenSelector(POSITIONS_SCREEN),
        label: NEXT_SCREEN_LABEL,
      };
    } else {
      return {
        onClick: () =>
          apiWrapper(
            () =>
              updateUserV2(
                formatPlayerAPIBody(
                  generalProps.data,
                  positionsProps.data,
                  skillsProps.data
                )
              ),
            SUCCESS_SUBMIT_MESSAGE,
            response => {
              setUser({ ...user, ...response.data });
              emit(USER_PLAYER_CARD_UPDATED_EVENT, user);
              onClose();
            },
            false,
            SUBMIT_ERROR_MESSAGE
          ),
        label: SAVE_PROFILE_LABEL,
      };
    }
  }, [
    apiWrapper,
    currentScreen,
    emit,
    generalProps.data,
    getScreenSelector,
    onClose,
    positionsProps.data,
    setUser,
    skillsProps.data,
    user,
  ]);

  return {
    // flow props
    currentScreen,
    flowButtonVariant,
    relativeModalNavigationProps: getNextScreenButtonProps(),
    screenTabsProps: {
      tabs: [
        {
          label: GENERAL_SCREEN,
          onClick: getScreenSelector(GENERAL_SCREEN),
          isSelected: currentScreen === GENERAL_SCREEN,
        },
        {
          label: SKILLS_SCREEN,
          onClick: getScreenSelector(SKILLS_SCREEN),
          isSelected: currentScreen === SKILLS_SCREEN,
        },
        {
          label: POSITIONS_SCREEN,
          onClick: getScreenSelector(POSITIONS_SCREEN),
          isSelected: currentScreen === POSITIONS_SCREEN,
        },
      ],
      initTab: currentScreen,
    },

    // individual screen props
    generalProps,
    skillsProps,
    positionsProps,
  };
};

export default useProps;
