import axios from 'axios';
import {
  ACCESS_TOKEN_EXPIRED,
  UNAUTHORIZED_ERR_CODE,
} from 'common/constants/error-codes';
import { apiPost, apiGet, apiPatch } from './apiWrapper';
import {
  LOGIN_API_ENDPOINT,
  LOGOUT_API_ENDPOINT,
  NOT_LOGGED_IN_ERR_MSG,
  REFRESH_TOKEN_API_ENDPOINT,
  REGISTER_API_ENDPOINT,
  RESET_PASSWORD_API_ENDPOINT,
  SHORT_REFRESH_INTERVAL,
  USER_API_ENDPOINT,
  VALIDATE_ACCOUNT_API_ENDPOINT,
} from './constants';

const isTokenExpired = errors =>
  errors.some(e => e.errorCode === ACCESS_TOKEN_EXPIRED);

export const handleUnauthorizedErr = err => {
  const errorCode = err?.response?.data?.errors?.[0]?.errorCode;

  if (
    errorCode === UNAUTHORIZED_ERR_CODE ||
    err.response.status === 401 ||
    err.response.status > 399
  )
    return;
};

export const registerService = apiPost(REGISTER_API_ENDPOINT);

export const loginService = apiPost(LOGIN_API_ENDPOINT);

export const requestAccountValidationLinkService = apiPost(
  VALIDATE_ACCOUNT_API_ENDPOINT
);

export const validateAccountService = token =>
  apiGet(`${VALIDATE_ACCOUNT_API_ENDPOINT}?token=${token}`)();

export const requestPasswordResetService = apiPost(RESET_PASSWORD_API_ENDPOINT);

export const resetPasswordService = (token, data) =>
  apiPatch(`${RESET_PASSWORD_API_ENDPOINT}?token=${token}`)(data);

export const logoutService = apiGet(LOGOUT_API_ENDPOINT);

export const initAutoRefreshToken = () => {
  axios.interceptors.response.use(
    response => response,
    err => {
      const storedReq = err.config;
      if (
        err.response.status === 401 &&
        isTokenExpired(err.response.data.errors)
      ) {
        return axios
          .get(REFRESH_TOKEN_API_ENDPOINT)
          .then(() => axios(storedReq))
          .catch(handleUnauthorizedErr);
      } else {
        throw err;
      }
    }
  );
};

export const refreshTokenInIntervals = () =>
  setInterval(() => {
    axios.get(REFRESH_TOKEN_API_ENDPOINT).catch(handleUnauthorizedErr);
  }, SHORT_REFRESH_INTERVAL);

const handleNotLoggedIn = res => {
  if (res.status === 204) throw new Error(NOT_LOGGED_IN_ERR_MSG);
  return axios.get(USER_API_ENDPOINT);
};

export const fetchUser = cb =>
  axios(REFRESH_TOKEN_API_ENDPOINT)
    .then(handleNotLoggedIn)
    .then(res => {
      cb(res?.data);
    })
    .catch(handleUnauthorizedErr);
