import PropTypes from 'prop-types';
import style from './GeneralScreen.module.scss';
import EventInputWithOptions, {
  EventInputWithOptionsPropTypes,
} from 'common/components/event-inputs/event-input-with-options';
import ScreenTitle from '../screen-title';
import GeneralPlayerInformationIcon from 'common/components/icons/general-player-information-icon';
import EventInputTextArea from 'common/components/event-inputs/event-input-textarea';
import { useCallback } from 'react';

const GeneralScreen = ({
  descriptionProps,
  interestedInOptionProps,
  personalityOptionProps,
  experienceOptionProps,
  footOptionProps,
}) => {
  const renderEventInputWithOptions = useCallback(() => {
    const props = [
      interestedInOptionProps,
      personalityOptionProps,
      experienceOptionProps,
      footOptionProps,
    ];

    return props.map((prop, index) => (
      <EventInputWithOptions key={index} {...prop} />
    ));
  }, [
    experienceOptionProps,
    footOptionProps,
    interestedInOptionProps,
    personalityOptionProps,
  ]);
  return (
    <div className={style['wrapper']}>
      <ScreenTitle Icon={GeneralPlayerInformationIcon} title='General' />

      <EventInputTextArea {...descriptionProps} />

      {renderEventInputWithOptions()}
    </div>
  );
};

GeneralScreen.propTypes = {
  descriptionProps: PropTypes.shape({
    // TODO -> EventInputTextAreaPropTypes
    inputLabel: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  }),
  interestedInOptionProps: PropTypes.shape(EventInputWithOptionsPropTypes),
  footOptionProps: PropTypes.shape(EventInputWithOptionsPropTypes),
  experienceOptionProps: PropTypes.shape(EventInputWithOptionsPropTypes),
  personalityOptionProps: PropTypes.shape(EventInputWithOptionsPropTypes),
};

export default GeneralScreen;
