import { useCallback, useState } from 'react';
import {
  CREATE_GAME_EVENT_MODAL_TYPE,
  PLAYER_PROFILE_SETUP_MODAL_TYPE,
} from './constants';
import { useProps } from './hooks';
import Match from 'features/match';
import { CreateEditGameEvent } from 'features/game-event-features';
import { HeaderWrapper } from '_metronic/layout/components/header/HeaderWrapper';
import PlayerForm from 'features/player-form';

const Navbar = () => {
  const { selectedMatch, closeMatchModal } = useProps();

  const [modalOpened, setModalOpened] = useState(null);
  const openCreateGameEventModal = useCallback(() => {
    setModalOpened(CREATE_GAME_EVENT_MODAL_TYPE);
  }, []);
  const openPlayerProfileSetupModal = useCallback(() => {
    setModalOpened(PLAYER_PROFILE_SETUP_MODAL_TYPE);
  }, []);

  const closeModal = useCallback(() => {
    setModalOpened(null);
  }, []);

  const quickLinksProps = {
    title: 'Quick Actions',
    linkItems: [
      {
        title: 'Create Game',
        description: 'Organize Next Game',
        icon: 'rocket',
        isOdd: true,
        onClick: openCreateGameEventModal,
        isActive: true,
      },
      {
        title: 'Set Attributes',
        description: 'Player Profile Wizard',
        icon: 'faceid',
        onClick: openPlayerProfileSetupModal,
        isActive: true,
        isOdd: false,
      },
      {
        title: 'Create Team',
        description: 'Available Soon',
        icon: 'shield',
        isOdd: true,
      },
      {
        title: 'Create League',
        description: 'Available Soon',
        icon: 'data',
        isOdd: false,
      },
    ],
  };

  const headerProps = {
    headerMenuItems: [
      {
        title: 'Dashboard',
        to: '/',
        type: 'item',
      },
    ],
  };

  return (
    <div>
      <HeaderWrapper
        quickLinksProps={quickLinksProps}
        headerProps={headerProps}
      />

      {/* -- MODALS -- */}
      {modalOpened === CREATE_GAME_EVENT_MODAL_TYPE && (
        <CreateEditGameEvent onClose={closeModal} />
      )}

      {modalOpened === PLAYER_PROFILE_SETUP_MODAL_TYPE && (
        <PlayerForm onClose={closeModal} />
      )}

      {selectedMatch && (
        <Match onClose={closeMatchModal} data={selectedMatch} />
      )}
    </div>
  );
};

export default Navbar;
