import PropTypes from 'prop-types';
import { PRIVATE_PAGE_TYPE } from 'common/constants/general';
import PageWrapper from '../../PageWrapper';
import Banner from 'common/components/banner';
import { banners } from '../../constants';

const PrivatePageWrapper = ({
  children,
  classNames,
  fullscreen,
  bannerType,
  skipMaxWidth,
}) => {
  return (
    <PageWrapper
      type={PRIVATE_PAGE_TYPE}
      classNames={classNames}
      fullscreen={fullscreen}
      skipMaxWidth={skipMaxWidth}
    >
      {bannerType !== undefined && <Banner img={banners[bannerType]} />}
      {children}
    </PageWrapper>
  );
};

PrivatePageWrapper.propTypes = {
  classNames: PropTypes.string,
  fullscreen: PropTypes.bool,
  bannerType: PropTypes.number,
};

export default PrivatePageWrapper;
